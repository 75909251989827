$(function() {
    const bannerSlider = $(".js-slider-banner");

    if(!!bannerSlider.length) {
        bannerSlider.on('init', function(event, slick) {
            $(this).append('<div class="banner__counter"><span class="banner__counter-current"></span> / <span class="banner__counter-total"></span></div>');
            $('.banner__counter-current').text(slick.currentSlide + 1);
            $('.banner__counter-total').text(slick.slideCount);
        }).slick({
            centerMode: false,
            infinite: true,
            arrows: true,
        }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            $('.banner__counter-current').text(nextSlide + 1);
        });
    }
})
